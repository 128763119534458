@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;
@use './mixins.scss' as *;
@use './overrides.scss';
.landing-page__illo {
  height: 150px;

  padding-bottom: $spacing-07 * 4;
  padding-left: $spacing-09 * 9;
}
.landing-page__illo_img {
  width: 25%;
  margin-left: $spacing-09 * 5;
}

.landing-page__banner {
  padding-top: $spacing-05;
  padding-bottom: $spacing-07 * 4;
  padding-left: $spacing-06;
  @include landing-page-background;
}
.landing-page__heading {
  @include type-style('productive-heading-05');
}
.landing-page__r2 {
  margin-top: rem(-40px);
}
.tabs-group-content {
  padding: $spacing-10 $spacing-06;
  padding-left: $spacing-09;
}
.tabs-group {
  padding-left: $spacing-09;
}

.landing-page__subheading {
  @include type-style('productive-heading-03');

  font-weight: 600;
}
.landing-page__r3 {
  padding-top: $spacing-09;
  padding-bottom: $spacing-09;
  padding-left: $spacing-06;
  @include landing-page-background;
}

.landing-page__label {
  @include type-style('heading-01');
}
.landing-page__banner,
.landing-page__r2,
.landing-page__r3 {
  margin-left: -20px;
  margin-right: -20px;

  @include breakpoint-down(md) {
    margin-left: 0;
    margin-right: 0;
  }
  .startButton {
    margin-left: 50%;
    -ms-transform: translateY(-50%) translateX(150%);
    transform: translateY(-50%) translateX(150%);
  }
  .vertical-center {
    margin-left: -20px;
    margin-right: -20px;
    width: 100%;
    position: absolute;
    -ms-transform: translateY(-50%) translateX(150%);
    transform: translateY(-50%) translateX(150%);
  }
}
