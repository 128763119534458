.stack_container_esgstd {
  width: 100%;
  height: 100vh;
}
.grid_container {
  margin-left: 0%;
  height: 85vh;
}
.treeviewViz {
  height: 90vh;
  overflow: scroll;
}
.data_header {
  height: 10%;
  width: 100%;
  margin-right: 1%;
  margin-left: 1%;
  display: flex;
}
.treeview_viewer {
  height: 100%;
  margin-left: 0%;
  background-color: #f4f4f4;
}
.document_name {
  width: 25% !important;
}
.div_button {
  display: flex;
  justify-content: space-between;
  position: fixed;
  background-color: #f4f4f4ce;
  height: 9%;
  z-index: 4;
  width: 100%;

  padding: 20px;
  border-radius: 20px;
  border: 1px;
  .forward {
    right: 0;
    margin-right: 15px;
    top: 4;
  }

  .back {
    right: 0;
    margin-right: 15px; /* Margine a sinistra per il pulsante "Avanti" */
    top: 4;
  }
  .save {
    right: 0;
    margin-right: 15px; /* Margine a sinistra per il pulsante "Avanti" */
    top: 4;
  }
  .home {
    right: 0;
    margin-right: 15px;
    top: 4;
  }
  .pptcreator {
    right: 0;
    margin-right: 15px;
    top: 4;
  }
  .text_input_name {
    left: 0;
    top: 0;
  }
  .text_input_modify {
    left: 30;
    top: 4;
  }

  /* Altre proprietà CSS per lo stile dei pulsanti */
}

.overlay-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 75%;
  height: 100%;
  background: rgba(230, 226, 226, 0.5);
  backdrop-filter: blur(0.7px);
  z-index: 3;
}
