.background {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  backdropfilter: blur(8px);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
}
.modal-img {
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  background: #000;
}
.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;

  p {
    margin-bottom: 1rem;
  }

  .button {
    flex-grow: 1;
    transform: translate(-50%, -50%);
    padding: 10px;
  }
}
.hori_center {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.option_select_sector {
  width: 800px;
}
.modal-button {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
}
