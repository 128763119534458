@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/theme' as *;
// overriding header tooltip bg color
// because the navigation is dark themed while the content is white
// which means the dark theme tooltip bg blends into the white content bg
.card {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  height: 102%;
  background-color: $layer-01;
  margin: 20px;
}

.card .imgBx {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
}

.card .imgBx:before {
  position: absolute;
  top: 0px;
  left: 24px;

  opacity: 0.2;
  font-size: 8em;
  font-weight: 800;
}

.card .imgBx img {
  position: relative;
  width: 100%;
  object-fit: fill;
}

.card .details {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  box-sizing: border-box;
  padding: 40px;
}

.card .details h2 {
  margin: 0;
  padding: 0;
  font-size: 2.4em;
  line-height: 1em;
  color: #444;
}

.card .details h2 span {
  font-size: 0.4em;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #999;
}

.card .details p {
  max-width: 85%;

  color: #333;
  font-size: 15px;
  margin-bottom: 36px;
}

.card .details h3 {
  margin: 0;
  padding: 0;
  font-size: 2.5em;
  color: #a2a2a2;
  float: left;
}
.card .details h5 {
  font-size: 1.5em;
  color: #a2a2a2;
}

.card .details button {
  border: none;
  outline: none;
  padding: 15px 20px;
  margin-top: 5px;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  float: right;
}

.product-colors span {
  height: 40px;
  top: 0%;
  margin-right: 12px;
  left: 10px;
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.black {
  background: #000;
}

.red {
  background: #d5212e;
}

.orange {
  background: #f18557;
}

/* responsive */
@media (max-width: 1080px) {
  .card {
    height: auto;
  }
  .card .imgBx {
    padding: 40px;
    box-sizing: border-box;
    width: 100% !important;
    height: auto;
    text-align: center;
    overflow: hidden;
  }
  .card .imgBx img {
    left: initial;
    max-width: 100%;
  }
  .details {
    width: 100% !important;
    height: auto;
    padding: 20px;
  }
  .card .details p {
    margin-left: 0;
    max-width: 100%;
  }
}
