@use '@carbon/react/scss/spacing' as sp;
@use '@carbon/react/scss/type' as type;
@use '@carbon/react/scss/breakpoint' as bp;
@use '@carbon/react/scss/theme' as theme;

.gri-102-summary {
  display: flex;
  align-items: center;
  margin-bottom: sp.$spacing-05;
  border: 1px solid;

  padding: sp.$spacing-05;
  transition: background-color 0.3s;

  &:hover {
  }

  input[type='checkbox'] {
    margin-right: sp.$spacing-03;
  }

  button {
    border: none;
    padding: sp.$spacing-03 sp.$spacing-05;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
    }
  }
}
