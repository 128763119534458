.card-block-template {
  padding: 5px;
  height: 150px;
  width: 350px;
  background-color: #fff;
  border: none;
  background-position: center;
  background-size: cover;
  transition: all 0.2s ease-in-out !important;
  border: 1px solid #ccc;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 30px 70px hsla(240%, 43%, 40%, 0.5);
  }
}
.selected-template {
  border-color: #007bff; /* Cambia il colore del bordo per il template selezionato */
}
