@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;
.LoginContainer {
  max-width: 30%;
  top: 50%;

  transform: translateY(50%) translateX(150%);
}
.stack_container {
  width: 80%;
}
.ButtonForm {
  transform: translateY(15%) translateX(160%);
}
.LogoLogin {
  transform: translateY(-15%) translateX(100%);
}
.SignOnRect {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ImgSignOn {
  margin: auto;
  display: block;
}
.FormSet {
  background-color: #fffefc;
}
