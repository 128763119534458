@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;
.OnWorkingContainer {
  position: absolute;
  width: 90%;
  left: 50%;
  transform: translate(-50%, 0%);
}
.stack_container {
  width: 80%;
}
.ButtonForm {
  transform: translateY(15%) translateX(160%);
}
.LogoLogin {
  transform: translateY(-15%) translateX(100%);
}
