.sidebar {
  position: fixed;
  top: 30;
  visibility: hidden; /* Nascondi inizialmente il menu laterale sulla destra */
  height: 90%;
  width: 20%;
  background-color: #faf8f8;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-out;
  transition: width 0.3s ease-out;
  z-index: 100;
  overflow-y: auto;
  scrollbar-width: none;
}
.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar.open {
  right: 0;
  visibility: visible;
}

.sidebar-content {
  margin-left: 10px;
}
.close-button {
  font-size: 20px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.enlarge-button {
  font-size: 20px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}
.sidebar.enlarged {
  width: 100%; /* Larghezza pari alla larghezza della finestra */
  transition: width 0.3s ease-out;
}
