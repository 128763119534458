@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;

@mixin newreport-page-background() {
  background-color: $layer-01;
  position: relative;
}
.newreport-page__illo {
  height: 300px;

  padding-bottom: $spacing-07 * 4;
  padding-left: $spacing-09 * 9;
}

.newreport-page__banner {
  padding-top: $spacing-05;
  padding-bottom: $spacing-09;
  padding-left: $spacing-06;
  @include newreport-page-background;
}
.newreport-page__heading {
  @include type-style('productive-heading-05');
}
.newreport-page__r2 {
  margin-top: rem(-40px);
}
.tabs-group-content {
  padding: $spacing-10 $spacing-06;
  padding-left: $spacing-09;
}
.tabs-group {
  padding-left: $spacing-09;
}

.newreport-page__subheading {
  @include type-style('productive-heading-03');

  font-weight: 600;
}
.newreport-page__r3 {
  padding-top: $spacing-09;
  padding-bottom: $spacing-09;
  padding-left: $spacing-06;
  @include newreport-page-background;
}

.newreport-page__label {
  @include type-style('heading-01');
}
.newreport-page__banner,
.newreport-page__r2,
.newreport-page__r3 {
  margin-left: -20px;
  margin-right: -20px;

  @include breakpoint-down(md) {
    margin-left: 0;
    margin-right: 0;
  }
  .startButton {
    margin-left: 50%;
    -ms-transform: translateY(-50%) translateX(150%);
    transform: translateY(-50%) translateX(150%);
  }
  .vertical-center {
    margin-left: -20px;
    margin-right: -20px;
    width: 100%;
    position: absolute;
    -ms-transform: translateY(-50%) translateX(150%);
    transform: translateY(-50%) translateX(150%);
  }
}
.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 50%;
  padding: 5px;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.container_checklist {
  position: relative;
  display: flow-root;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}
.progress-position {
  position: absolute;
  z-index: 2;
  width: 100%;
}
.popUp-display {
  position: absolute;
  z-index: -1;
}
.hori_center {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding-top: 3%;
}
.form_controller {
  display: block;
  width: 100%;
}
.newreport-page__prgs {
  padding-top: $spacing-05;
  padding-left: 10%;
  padding-bottom: 0%;
}
.hidden {
  display: none; /* Nasconde completamente il componente */
  /* o
  visibility: hidden; Nasconde il componente lasciando uno spazio vuoto al suo posto */
}

/* Stile per il componente visibile */
.visible {
  display: block; /* Mostra il componente */
  /* o
  visibility: visible; Mostra il componente lasciando uno spazio vuoto al suo posto */
}
.visibleInvalid {
  font-size: smaller;
  color: red;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.visibleRiep {
  display: block;
  background: transparent; /* Imposta lo sfondo del div come trasparente */
  pointer-events: none;
}
