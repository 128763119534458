@use '@carbon/react/scss/colors';

// overriding header tooltip bg color
// because the navigation is dark themed while the content is white
// which means the dark theme tooltip bg blends into the white content bg
.color_footer {
  background-color: colors.$gray-30;
  position: fixed;
  bottom: 0;
}
.ESGTableConsumi {
  width: 100%&;
}
