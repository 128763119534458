@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;
.LoginContainer {
  position: absolute;

  transform: translate(50%, -50%);
  width: 40%;
}
.stack_container2 {
  position: absolute;
  transform: translate(70%, 30%);
  width: 40%;
}
.container {
  width: 40%;
}
.ButtonForm {
  transform: translateY(150%) translateX(160%);
}
.LogoLogin {
  transform: translateY(-45%) translateX(100%);
}
