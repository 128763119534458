// Stili per il contenitore principale
.address-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: flex-start;
}

// Stili per il wrapper dei select
.address-select {
  width: 100%; // Rende i select a larghezza completa
  margin-bottom: 20px;
  .bx--select {
    width: 100%; // Rende i select a larghezza completa
  }
  label {
    font-size: smaller;
  }
}

// Stili per il wrapper dei campi di input
.address-input {
  width: 100%; // Rende i campi di input a larghezza completa
  margin-bottom: 20px;
  label {
    font-size: smaller;
  }
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
}
